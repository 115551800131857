.dashboard-right {
  height: 100vh;
  width: 85vw;

  .dashboard-content {
    padding: 0 1vw;
    height: calc(100vh - 80px);

    .component-wrapper {
      width: 100%;
      height: 100%;
    }
  }
}

.component-wrapper {
  width: 100%;
  height: 100%;

  .ant-spin-nested-loading {
    height: 100% !important;
  }
}

.coming-soon {
  text-align: center;
  vertical-align: middle;
  height: 100vh;
  width: 85vw;
  font-size: 24px;
  font-weight: bold;
  margin-top: 5%;
}

@media(max-width:1300px) {
  .dashboard-right {
    width: calc(100vw - 180px);
  }

  .coming-soon {
    width: calc(100vw - 180px);
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;