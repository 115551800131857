.bank-detail-modal {
  width: 481px;
  height: 426px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 20%;

  .success-modal {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  .bnkd-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-card-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .TextLevelSmallLabel {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    color: #6B6B6B;
  }

  .done-btn {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    text-align: center;
    width: 315px;
    height: 60px;
    padding: 16px;
    background: #003B5B;
    box-shadow: 0px 8px 20px rgba(0, 59, 91, 0.16);
    border-radius: 10px;
    cursor: pointer;
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;