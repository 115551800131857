.membershipPackages {
  padding: 1rem 1.2rem;
  gap: 1rem;
  display: grid;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  grid-template-columns: repeat(2, 1fr);

  .membership {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 20px;
    background: #F4F7FA;
    border-radius: 15px;
    row-gap: 10px;
    max-width: 600px;
    min-width: 360px;
    overflow: hidden;
    position: relative;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .heading {
        font-weight: 600;
        color: #003B5B;
        font-size: 1.4rem;
      }

      .status {
        font-weight: 500;
        font-size: 16px;
        color: #DB524E;
        background: rgba(247, 0, 0, 0.2);
        border-radius: 5px;
        padding: 5px 15px;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          height: 7px;
          width: 7px;
          background: #DB524E;
          border-radius: 50%;
          margin-right: 4px;
        }
      }

      .active {
        color: #27AE60;
        background: rgba(39, 174, 96, 0.2);

        span {
          background: #27AE60;
        }
      }
    }

    .price {
      font-weight: 500;
      color: #E2AE2C;
    }

    .detail {
      width: 100%;
      font-weight: 400;
      color: rgba(107, 107, 107, 0.6);
      height: 60px;
      overflow: hidden;

      span {
        font-weight: 400;
        color: gray !important;
        // cursor: pointer;
      }
    }

    .scrollY {
      overflow-y: auto;
    }

    .showMore-wrapper {
      height: 25px;
      margin-bottom: 45px;
    }

    .show {
      color: #005586;
      cursor: pointer;
      font-weight: 600;
    }

    button {
      width: 150px;
      height: 44px;
      background: #003B5B;
      box-shadow: 0px 8px 20px rgba(0, 59, 91, 0.16);
      border-radius: 10px;
      font-weight: 600;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      text-align: center;
      border: none;
      outline: none;

      .icon {
        margin: 0 0 7px 10px;
      }
    }
  }

  .circleimg1 {
    position: absolute;
    right: -60px;
    bottom: 0px;
  }

  .circleimg2 {
    position: absolute;
    right: 25px;
    bottom: 0px;
  }
}

.nosubscription {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10% auto 0;

  p {
    color: #6B6B6B;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.5;
  }
}

.create-membership {
  position: fixed;
  width: 60px;
  height: 60px;
  right: 2vh;
  bottom: 2vh;
  background: #003B5B;
  border-radius: 50px;
  outline: none;
  border: none;

  .plus-outlined svg {
    height: 30px;
    width: 30px;
    font-size: large;
    color: white;
    font-weight: 900;
  }
}

@media screen and (max-width: 500px) {
  .membershipPackages {
    grid-template-columns: repeat(1, 1fr);

    .heading {
      font-size: 18px;
    }

    .price {
      font-size: 16px;
    }

    .detail {
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 501px) and (max-width: 820px) {
  .membershipPackages {
    grid-template-columns: repeat(1, 1fr);

    .heading {
      font-size: 20px;
    }

    .price {
      font-size: 18px;
    }

    .detail {
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 821px) and (max-width: 1600px) {
  .membershipPackages {
    grid-template-columns: repeat(2, 1fr);

    .heading {
      font-size: 22px;
    }

    .price {
      font-size: 18px;
    }

    .detail {
      font-size: 14px;
    }
  }
}

@media screen and (min-width: 1600px) {
  .membershipPackages {
    grid-template-columns: repeat(3, 1fr);

    .heading {
      font-size: 24px;
    }

    .price {
      font-size: 22px;
    }

    .detail {
      font-size: 14px;
    }
  }
}

@media screen and (min-width: 2000px) {
  .membershipPackages {
    grid-template-columns: repeat(4, 1fr);
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;