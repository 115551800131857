* {
  font-family: "Poppins", sans-serif;
}

html,
body {
  min-width: 100vw;
  height: 100vh;
}

#root {
  min-width: 100vw;
  height: 100vh;
  overflow: hidden;
}

::-webkit-scrollbar {
  background: transparent;
  width: 0;
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;