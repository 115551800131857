.navbar-container {
 // box-shadow: 0px 3px 6px rgba(0, 59, 91, 0.05);
  height: 80px;

  .navbar-wrapper {
    padding: 10px 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .heading {
      font-weight: 600;
      font-size: 28px;
      color: #003B5B;
      text-transform: capitalize;
    }

    .search-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: "10px";
      position: relative;
      width: 284px;
      height: 50px;
      background: #FAFAFA;
      border: 1px solid #F1F1F1;
      border-radius: 12px;
      left:10%;

      .search-input {
        width: 100%;
        height: 100%;
        padding-left: 3rem;
        font-size: 16px;
        font-weight: 500;
        background-color: #FAFAFA;
      }

      ::placeholder {
        font-size: 16px;
        font-weight: 500;
        color: rgba(17, 17, 17, 0.2);
      }

      .searchicon {
        width: 22px;
        height: 22px;
        position: absolute;
        z-index: 1;
        left: 14px;
        top: 13px;
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 1rem;

      .filter-wrapper,
      .notification-wrapper {
        img {
          cursor: pointer;
        }
      }
      .filter-wrapper{
        .triangle{
            position:relative;
            top:2px;
        }
        .triangle::after{
          content: "";
    position: absolute;
  z-index:10000;
    left: 50% !important;
    margin-left: -8px;
    border-width: 19px;
    border-style: solid;
    border-color: #FFFFFF; 
    border-left: 10px solid transparent;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
        }
      }

      .logo {
        background: #FFFFFF;
        box-shadow: 0px 10px 34px rgba(0, 59, 91, 0.07);
        border-radius: 12px;
        max-width: 240px;
        height: 60px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0 1rem;
        column-gap: 1rem;

        .businessName {
          font-weight: 500;
          font-size: 18px;
          color: #003B5B;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        img {
          width: 45px;
          height: 45px;
          border-radius: 50%;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .navbar-container {
    .heading {
      font-size: 2.25vw !important;
    }
  }
  .search-wrapper {
    left:6% !important;
  }
}
@media screen and (max-width: 1200px) {
  .search-wrapper {
    left:0px !important;
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;